import React from "react";
import { createContext, useContext } from "react";
import { useFeatureFlags, initialFeatureFlags, FeatureFlags } from "hooks/useFeatureFlags";

type ContextProps = {
  featureFlags: FeatureFlags;
  isFetched: boolean;
};

const FeatureFlagsContext = createContext<ContextProps>({
  featureFlags: initialFeatureFlags,
  isFetched: false,
});

export const useFeatureFlagsContext = () => {
  const context = useContext(FeatureFlagsContext);
  if (!context) {
    throw Error("useFeatureFlagsContext must be used within a useFeatureFlagsProvider");
  }
  return context;
};

export const FeatureFlagsProvider = (props: any) => {
  const { featureFlags, isFetched } = useFeatureFlags();
  return <FeatureFlagsContext.Provider value={{ featureFlags, isFetched }} {...props} />;
};
