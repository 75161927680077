import { StoreState } from "reducers";
import { ReturnSteps } from "actions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getRoutes } from "constants/routes";

export function useCheckCurrentStep(currentStep: ReturnSteps) {
  const returnStep = useSelector((state: StoreState) => state.returnStep);
  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const returnOrder = useSelector((state: StoreState) => state.order);
  const { returnOrderId, status } = returnOrder;

  const ROUTES = getRoutes(shopSlug, returnOrderId);
  const history = useHistory();

  useEffect(() => {
    if (status === "pending_payment_on_submission") {
      const redirectUrl = `${process.env.REACT_APP_NEW_CUSTOMER_APP_URL}/${shopSlug}/returns/${returnOrderId}/submission_payment`;
      window.location.href = redirectUrl;
    } else if (!["draft", "update_draft"].includes(status)) {
      const detailsUrl = `/${shopSlug}/returns/${returnOrderId}/details`;
      history.push(detailsUrl);
    } else if (returnStep && returnStep < currentStep) {
      history.push(ROUTES[returnStep]);
    }
  }, [returnStep, shopSlug, ROUTES, history, currentStep, status]);
}
