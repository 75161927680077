import { Dispatch } from "redux";
import { History } from "history";

import {
  clearErrors,
  clearLoading,
  setError,
  setLoading,
  changeReturnStep,
  ReturnSteps,
  addReturnItems,
  addBonusCreditInfo,
  ReturnItem,
  setQuestions,
  setInstantExchange,
} from ".";
import { StoreState } from "reducers";
import { createReturnOrder, updateReturnOrder, retrieveReturnOrder, proceedToPayment } from "api";
import { getRoutes } from "constants/routes";
import { ConflictError } from "api/constants";

export interface ReturnOrder {
  address1: string;
  address2: string;
  city: string;
  country: string;
  created_at: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  order_id: string;
  phone: string;
  province: string;
  shop_id: number;
  updated_at: string;
  zip: string;
}

export const retrieveOrCreateReturnOrder = (orderIdOrName: string, emailOrPhone: string) => {
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    dispatch(clearErrors());
    dispatch(setLoading());

    const {
      currentShop: { id: shopId, shopSlug },
      locale: { code: localeCode },
    } = getState();

    try {
      const params = { orderIdOrName, emailOrPhone, shopId, localeCode };
      let response = await retrieveReturnOrder(params);
      if (!response.returnOrder) {
        response = await createReturnOrder(params);
      }
      const { returnOrder, nextStep } = response;
      const nextStepEnum = Object.values(ReturnSteps).indexOf(nextStep);
      const bonusCreditInfo = {
        exchangeBonusCreditMaxValuePerOrder: returnOrder.exchangeBonusCreditMaxValuePerOrder,
        storeCreditBonusCreditMaxValuePerOrder: returnOrder.storeCreditBonusCreditMaxValuePerOrder,
      };
      dispatch(addBonusCreditInfo(bonusCreditInfo));

      dispatch(setInstantExchange(returnOrder.instantExchange));
      const returnItems: ReturnItem[] = [...returnOrder.exchanges, ...returnOrder.refunds];
      dispatch(addReturnItems(returnItems));
      dispatch(setQuestions(returnOrder.questions));

      dispatch(changeReturnStep(nextStepEnum));
      dispatch(clearLoading());

      const ROUTES = getRoutes(shopSlug, returnOrder.id);
      return { nextRoute: ROUTES[nextStepEnum], returnOrder: returnOrder };
    } catch (err) {
      dispatch(setError(err));
      dispatch(clearLoading());
    }
  };
};

export const updateReturnOrderThenContinue = (
  history: History,
  currentStep: ReturnSteps,
  options?: { continueToSubmittedStep?: boolean },
) => {
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    dispatch(clearErrors());
    dispatch(setLoading());
    const {
      order,
      returnItems,
      order: { shippingAddress },
      currentShop: { id: shopId, shopSlug },
      locale: { code: localeCode },
    } = getState();

    try {
      const returnOrder = await updateReturnOrder({
        order,
        returnItems,
        shippingAddress,
        returnStep: currentStep,
        shopId,
        localeCode,
      });
      const nextStepToUse = options?.continueToSubmittedStep
        ? ReturnSteps.submitted
        : ReturnSteps[returnOrder.nextStep as keyof typeof ReturnSteps];

      dispatch(clearLoading());
      dispatch(changeReturnStep(nextStepToUse));
      const ROUTES = getRoutes(shopSlug, returnOrder.id);
      history.push(ROUTES[nextStepToUse]);
    } catch (err) {
      if (err instanceof ConflictError) {
        history.push(err.options.redirect_url);
      } else {
        dispatch(setError(err));
      }
      dispatch(clearLoading());
    }
  };
};

export const proceedReturnOrderToPayment = (history: History, returnStep: ReturnSteps) => {
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    dispatch(clearErrors());
    dispatch(setLoading());
    const {
      returnItems: { instantExchange },
      order: { returnOrderId },
      currentShop: { shopSlug },
      locale: { code: localeCode },
    } = getState();

    try {
      await proceedToPayment(instantExchange, returnOrderId, returnStep);
      const submissionUrl = `${process.env.REACT_APP_NEW_CUSTOMER_APP_URL}/${shopSlug}/returns/${returnOrderId}/submission_payment?locale=${localeCode}`;
      window.location.href = submissionUrl;
    } catch (err) {
      if (err instanceof ConflictError) {
        history.push(err.options.redirect_url);
      }
      dispatch(setError(err));
      dispatch(clearLoading());
    }
  };
};
