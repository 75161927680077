import React from "react";
import { Button } from "components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { updateReturnOrderThenContinue, ReturnSteps, proceedReturnOrderToPayment } from "actions";
import "react-slidedown/lib/slidedown.css";
import { useAuthContext } from "contexts/AuthProvider";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

export const PaymentAndSubmission: React.FC<any> = (): JSX.Element => {
  const instantExchange = useSelector((store: StoreState) => store.returnItems.instantExchange);

  const { requiresPayment } = useInvoiceContext();
  const { broadcastLogout } = useAuthContext();
  const { t } = useTranslation("orderFlow");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = () => {
    if (requiresPayment) {
      dispatch(proceedReturnOrderToPayment(history, ReturnSteps.summary));
    } else {
      dispatch(updateReturnOrderThenContinue(history, ReturnSteps.summary, { continueToSubmittedStep: true }));
      broadcastLogout();
    }
  };

  const buttonLabel = requiresPayment ? t("exchangePaymentPage.proceedToPayment") : t("orderSummary.submit");

  return (
    <>
      <br />
      <div style={{ paddingInline: "14px" }}>
        <Button color="primaryColor" onClick={handleSubmit} fluid>
          {buttonLabel}
        </Button>
        {requiresPayment && !instantExchange && (
          <p
            style={{
              color: "gray",
              fontStyle: "italic",
              width: "100%",
              textAlign: "center",
              fontSize: "0.95rem",
              marginTop: "6px",
            }}
          >
            {t("summary.paymentHelpText")}
          </p>
        )}
      </div>
    </>
  );
};
