import * as yup from "yup";
import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
const countryParser = require("@ejarnutowski/country-parser");

const US_TERRITORIES_ALPHA2_CODES = ["AS", "GU", "MP", "PR", "VI"];

function validatePhoneCountry(phone: string, country: string): boolean {
  // Changing it to guessCode for translations, otherwise it will not return the correct code
  const countryCode = countryParser.guessCode(country, true);
  const parsedPhone = parsePhoneNumber(phone);
  if (!parsedPhone) return false;
  if (!parsedPhone.country) return false;

  if (countryCode === "US") {
    // check in case US customer using US territories phone number
    return [...US_TERRITORIES_ALPHA2_CODES, "US"].includes(parsedPhone.country);
  } else {
    return parsedPhone.country === countryCode;
  }
}

export const contactDetailsValidationShape = {
  firstName: yup.string().required().label("First name"),
  lastName: yup.string().required().label("Last name"),
  phone: yup
    .string()
    .required("Phone number is required")
    .test("is valid phone number", "", function (val: string) {
      const {
        path,
        parent: { country },
        createError,
      } = this;

      const { skipCountryValidation } = this.options.context as { skipCountryValidation: boolean };

      if (!val || !isPossiblePhoneNumber(val) || !isValidPhoneNumber(val)) {
        return createError({
          path,
          message: "Please enter a valid phone number",
        });
      }

      if (skipCountryValidation) {
        return true;
      }

      if (!validatePhoneCountry(val, country)) {
        return createError({
          path,
          message: `Please enter a valid ${country} number`,
        });
      }

      return true;
    }),
  email: yup
    .string()
    .required("Please enter your email address")
    .test(
      "postmark-email-validation",
      "Please ensure the email address is in the correct format and does not contain special characters",
      (value) => {
        if (!value) return true;

        const emailParts = value.split("@");
        if (emailParts.length !== 2) return false;

        const mailboxRegex = /^[a-zA-Z0-9\.!#\$%&'`\*\+\-\/=\^_\{\}\|~]+$/;
        const domainRegex = /([\w-]+\.)+[\w-]{2,5}$/;
        const mailbox = emailParts[0];
        const domain = emailParts[1];

        if (!mailboxRegex.test(mailbox) || !domainRegex.test(domain)) return false;

        return true;
      },
    ),
};
