import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Header, Form, TextArea } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Button } from "components";
import { setCurrentReturnItemProperty, FormModalSteps, formModalPush, UploadedImage } from "actions";
import { StoreState } from "reducers";
import styles from "./ExtraInformationForm.module.css";
import { Formik, FormikProps } from "formik";
import { FormModalContent } from "..";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import AwsS3 from "@uppy/aws-s3";
import Compressor from "@uppy/compressor";
import Webcam from "@uppy/webcam";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/webcam/dist/style.min.css";
import { capitalize } from "helpers";

export const ExtraInformationForm: React.FC<any> = () => {
  const { t } = useTranslation("orderFlow");
  const dispatch = useDispatch();
  const extraNote = useSelector((state: StoreState) => state.returnItems.currentReturnItem.extraNote);
  const currentReturnItem = useSelector((state: StoreState) => state.returnItems.currentReturnItem);
  const selectedReason = currentReturnItem.returnReason;
  const initialValue = { extraNote };
  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([]);
  const [uppyDashboardStyle, setUppyDashboardStyle] = useState({});
  const megabyte = 1024 * 1024;
  const second = 1000;

  const locale = useSelector((state: StoreState) => state.locale.code);
  const uiSetting = useSelector((state: StoreState) => state.currentShop.userInterfaceSetting);

  const customImageUploadLabel = uiSetting.returnReasonImageUploadLabelTranslations[locale];
  const imageUploadLabel = `${customImageUploadLabel || t("upload")} (${uploadedImages.length}/5)`;

  const customExtraNoteLabel = uiSetting.returnReasonExtraNoteLabelTranslations[locale];
  const extraNoteLabel = customExtraNoteLabel || t("extraNote");

  const [uppy] = useState(
    new Uppy({
      id: "myUppy",
      autoProceed: true,
      restrictions: {
        allowedFileTypes: ["image/jpeg", "image/png", "image/heic", "image/heif", "image/webp", "image/avif"],
        minNumberOfFiles: 1,
        maxNumberOfFiles: 5,
        maxFileSize: 10 * megabyte,
      },
    }),
  );

  useEffect(() => {
    if (!uppy) return;
    if (!selectedReason.imageUpload && !selectedReason.optionalImageUpload) {
      setUppyDashboardStyle({ display: "none" });
      return;
    }

    uppy
      .use(Dashboard, {
        target: "#uppyDashboard",
        inline: true,
        height: 400,
        proudlyDisplayPoweredByUppy: false,
        hideProgressAfterFinish: true,
        showRemoveButtonAfterComplete: true,
        locale: {
          strings: {
            dropPasteImportFiles: t("uppyDashboard.dropPasteImportFiles"),
            browseFiles: t("uppyDashboard.browseFiles"),
            myDevice: t("uppyDashboard.myDevice"),
          },
        },
      })
      .use(Webcam, {
        target: Dashboard,
        modes: ["picture"],
        locale: { strings: { pluginNameCamera: t("uppyWebcam.pluginNameCamera") } },
      })
      .use(Compressor)
      .use(AwsS3, {
        timeout: 60 * second,
        companionUrl: `${process.env.REACT_APP_SERVER_URL}/`, // default to /s3/params
      })
      .on("file-removed", (file, _reason) => {
        setUploadedImages((images) => images.filter((image) => image.uppyFileId !== file.id));
      })
      .on("upload-success", (file, response) => {
        if (!file) return null;

        const uploadURL = response.uploadURL || "";
        const regex = /\/([a-f0-9]{32}\.[a-z0-9]+)$/i;
        const match = uploadURL.match(regex);
        const id = match && match[1] ? match[1] : null;

        // construct uploaded file data in the format that Shrine expects, except uppyFileId
        const uploadedImage = {
          id,
          uppyFileId: file.id,
          storage: "r2_cache",
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        };
        setUploadedImages((images) => [...images, uploadedImage]);
      });

    return () => {
      if (uppy) uppy.close({ reason: "unmount" });
    };
  }, [t, uppy, selectedReason.imageUpload]);

  const extraNoteField = (values: { extraNote: string }, setFieldValue: { (field: string, value: string): void }) => {
    return (
      <Grid.Column mobile={16}>
        <Grid>
          <Grid.Column mobile={16} className={styles.headerContainer}>
            <Header as="h5">
              {extraNoteLabel} {selectedReason.optionalExtraNote && ` (${capitalize("optional")})`}
            </Header>
            <TextArea
              name="extraNote"
              value={values.extraNote}
              onChange={(event) => {
                setFieldValue("extraNote", event.currentTarget.value);
              }}
            />
          </Grid.Column>
        </Grid>
      </Grid.Column>
    );
  };

  function handleFormOnSubmit(values: any) {
    if (selectedReason.imageUpload || selectedReason.optionalImageUpload) {
      dispatch(setCurrentReturnItemProperty("images", uploadedImages));
    }
    if (selectedReason.extraNote || selectedReason.optionalExtraNote) {
      dispatch(setCurrentReturnItemProperty("extraNote", values.extraNote));
    }
    dispatch(formModalPush(FormModalSteps.stageThreeQuestions));
  }

  function isDisabled(values: { extraNote: string }) {
    if (selectedReason.imageUpload && uploadedImages.length === 0) return true;
    if (selectedReason.extraNote && !values.extraNote) return true;
    return false;
  }

  return (
    <FormModalContent>
      <Formik initialValues={initialValue} onSubmit={handleFormOnSubmit}>
        {({ handleSubmit, setFieldValue, values }: FormikProps<any>): JSX.Element => (
          <Form onSubmit={handleSubmit}>
            <Grid>
              <Grid.Column mobile={16} style={uppyDashboardStyle}>
                <Header as="h5">
                  {imageUploadLabel} {selectedReason.optionalImageUpload && ` (${capitalize("optional")})`}
                </Header>
                <div id="uppyDashboard" className={styles.uppyPreviewImage} />
              </Grid.Column>
              {(selectedReason.extraNote || selectedReason.optionalExtraNote) && extraNoteField(values, setFieldValue)}
            </Grid>
            <div className={styles.submitRow}>
              <Button color="primaryColor" disabled={isDisabled(values)} fluid type="submit">
                {t("continue")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </FormModalContent>
  );
};
