import React from "react";
import { Button } from "components";
import "./style.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateReturnOrderThenContinue, ReturnSteps, proceedReturnOrderToPayment } from "actions";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "contexts/AuthProvider";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { StickyBottomAppBar } from "StickyBottomAppBar";

export const PaymentAndSubmission: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("orderFlow");
  const { requiresPayment } = useInvoiceContext();
  const { broadcastLogout } = useAuthContext();

  const handleSubmit = () => {
    if (requiresPayment) {
      dispatch(proceedReturnOrderToPayment(history, ReturnSteps.summary));
    } else {
      dispatch(updateReturnOrderThenContinue(history, ReturnSteps.summary, { continueToSubmittedStep: true }));
      broadcastLogout();
    }
  };

  const buttonLabel = requiresPayment ? t("exchangePaymentPage.proceedToPayment") : t("orderSummary.submit");

  return (
    <>
      <div style={{ paddingBottom: "3rem" }}>
        <br />
      </div>
      <StickyBottomAppBar>
        <Button
          style={{ margin: "1rem 0.5rem", width: "-webkit-fill-available" }}
          color="primaryColor"
          onClick={handleSubmit}
        >
          {buttonLabel}
        </Button>
      </StickyBottomAppBar>
    </>
  );
};
