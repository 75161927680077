import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import PostCoLogo from "./PostCoLogo";
import { StoreState } from "reducers";
import styles from "./BackgroundImage.module.css";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import { useFeatureFlagsContext } from "contexts/FeatureFlagsProvider";
import { getBackgroundImageUrl } from "./helpers";

export const BackgroundImage: React.FC = ({ children }) => {
  const { t } = useTranslation(["translation", "orderFlow"]);
  const backgroundImageUrls = useSelector((state: StoreState) => state.frontendConfigs.backgroundImageUrls);
  const mobileBackgroundImageUrls = useSelector((state: StoreState) => state.frontendConfigs.mobileBackgroundImageUrls);
  const {
    featureFlags: { whiteLabel },
    isFetched,
  } = useFeatureFlagsContext();

  const { isEmbedded } = useIsEmbedded();
  const customStyle = isEmbedded ? { height: "100vh" } : undefined;

  const smallWebpUrl = getBackgroundImageUrl(backgroundImageUrls, mobileBackgroundImageUrls, "webp", "small");
  const mediumWebpUrl = getBackgroundImageUrl(backgroundImageUrls, mobileBackgroundImageUrls, "webp", "medium");
  const largeWebpUrl = getBackgroundImageUrl(backgroundImageUrls, mobileBackgroundImageUrls, "webp", "large");
  const smallJpgUrl = getBackgroundImageUrl(backgroundImageUrls, mobileBackgroundImageUrls, "jpg", "small");
  const mediumJpgUrl = getBackgroundImageUrl(backgroundImageUrls, mobileBackgroundImageUrls, "jpg", "medium");
  const largeJpgUrl = getBackgroundImageUrl(backgroundImageUrls, mobileBackgroundImageUrls, "jpg", "large");

  return (
    <div className={styles.main} style={customStyle} id="overlay">
      <picture className={styles.picture}>
        <source media="(max-width:768px)" type="image/webp" srcSet={smallWebpUrl} />
        <source media="(max-width:1200px)" type="image/webp" srcSet={mediumWebpUrl} />
        <source media="(min-width:1201px)" type="image/webp" srcSet={largeWebpUrl} />
        <source media="(max-width:768px)" type="image/jpg" srcSet={smallJpgUrl} />
        <source media="(max-width:1200px)" type="image/jpg" srcSet={mediumJpgUrl} />
        <source media="(min-width:1201px)" type="image/jpg" srcSet={largeJpgUrl} />
        <img src={largeJpgUrl} width="auto" alt="background" />
      </picture>
      {children}
      {isFetched && !whiteLabel && (
        <div className={styles.footer}>
          <p className={styles.footerText}>{t("poweredBy")}</p>
          <a href="https://postco.co" rel="noopener noreferrer" target="_blank">
            <PostCoLogo />
          </a>
        </div>
      )}
    </div>
  );
};
